import Vue from 'vue';

/**
 * Mutates nested property of the passed state
 * This code fragment is duplicated through many VUEX modules as `SET_${module}_ATOMIC`
 * We should be able to utilize this throughout the app
 * @param state you are mutating
 * @param key you are accessing with dot notation, ie: booking._embedded.client
 * @param val will be the property's new value
 */
export const setAtomicProperty = (state, key, val) => {
  const nodes = key.split('.');
  let obj = state;
  let i = 0;
  nodes.forEach((node) => {
    i += 1;
    if (i === nodes.length) {
      Vue.set(obj, node, val);
    } else {
      obj = obj[node];
    }
  });
}
