import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { reportError } from '@/utils/helpers/tracking/tracking-methods';

const defaultCompany = {
  commission_split: 20,
  back_office: 'none',
  currency: {
    symbol: 'USD',
  },
  _embedded: {
    address: {},
    employees: [],
    host_agencies: [],
    iata_plans: [
      {
        iata: {},
        plan: {},
      },
    ],
    invoice_schedule: {
      dates: [1, 15],
      rule: 'monthly',
      interval: ['monthly'],
    },
    contractor_configuration: {
      subscriptions: [],
      cc_addresses: [],
    },
  },
};

const defaultContractorConfig = {
  subscriptions: [],
  cc_addresses: [],
}

const state = {
  agency: {},
  company: cloneDeep(defaultCompany),
  companies: [],
  contractors: [],
  editing: cloneDeep(defaultCompany),
  contractor_configuration: cloneDeep(defaultContractorConfig),
  trigger: false,
  refresh: false,
  settings: {
    contractors: {
      loading: false,
      checked: false,
    },
  },
};

const mutations = {
  ADD_IATA_PLAN_FOR_COMPANY_EDITING(state, payload) {
    state.editing._embedded.iata_plans.splice(payload.index, 0, payload.val);
  },
  SET_AGENCY(state, agency) {
    state.agency = agency;
  },
  SET_COMPANY(state, company) {
    state.company = company;
  },
  SET_COMPANY_CONTRACTORS(state, contractors) {
    state.contractors = contractors;
  },
  SET_COMPANY_EDITING(state, company) {
    state.editing = company;
  },
  SET_CONTRACTOR_CONFIGURATION_DEFAULT(state) {
    state.contractor_configuration = cloneDeep(defaultContractorConfig);
  },
  SET_COMPANY_EDITING_DEFAULT(state) {
    state.editing = cloneDeep(defaultCompany);
  },
  SET_COMPANY_EDITING_FIELD(state, field) {
    const nodes = field.key.split('.');
    let obj = state.editing;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_COMPANY_EDITING_IATA_FIELD(state, field) {
    const nodes = field.key.split('.');
    let obj = state.editing._embedded.iatas[field.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_COMPANY_EDITING_IATA_PLAN_FIELD(state, field) {
    const nodes = field.key.split('.');
    let obj = state.editing._embedded.iata_plans[field.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_COMPANY_FIELD(state, field) {
    const nodes = field.key.split('.');
    let obj = state.company;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_COMPANY_MODAL_TRIGGER(state, trigger) {
    state.trigger = trigger;
  },
  SET_COMPANY_REFRESH(state, refresh) {
    state.refresh = refresh;
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
  SET_COMPANIES_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_IATA_PLAN_FIELD_FOR_COMPANY_EDITING(state, payload) {
    let obj = state.editing._embedded.iata_plans[payload.index];
    const nodes = payload.key.split('.');
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  company: (state) => state.company,
  companyEmployees: (state) => {
    const { company, contractors } = state;
    let list = company._embedded.employees;
    contractors.forEach((contractor) => {
      const { invite, employees } = contractor._embedded;
      if (invite && invite.ha.view_agents) {
        list = list.concat(employees);
      }
    });
    return list;
  },
  companyEmployeesUnfiltered: ({ company, contractors }) => {
    let list = company._embedded.employees;
    contractors.forEach((contractor) => {
      const { employees } = contractor._embedded;
      list = list.concat(employees);
    });
    return list;
  },
  companyEmployeesForBookingEditing: (state, getters, rootState, rootGetters) => {
    const bookingEditing = rootGetters['bookings/bookingEditing'];
    let employees = [];
    if (bookingEditing.company_id) {
      employees = getters.companyEmployeesUnfiltered.filter(
        (employee) => {
          const isCompany = employee.company_id === bookingEditing.company_id;
          const isAllowed = employee.access?.personal.bookings.write;
          return (isCompany && isAllowed);
        }
      );
    }
    return employees;
  },
  companyContractors: (state) => state.contractors,
  companyHostAgencies: (state) => state.company?._embedded?.host_agencies || [],
  companyContractorsLoading: (state) => state.settings.contractors.loading,
  contractorConfigurationEditing: (state) => state.contractor_configuration,
  companyContractorsNoAgent: ({ contractors }) =>
    contractors.filter((contractor) => {
      const { invite } = contractor._embedded;
      if (invite) return !invite.ha.view_agents;
      return false;
    }),
  companyContractorsChecked: (state) => state.settings.contractors.checked,
  companyEditing: (state) => state.editing,
  companyFeatureFlags: (state) => state.company._embedded?.feature_flags || [],
  companyMergeFeatureFlag: (state, getters) => getters.companyFeatureFlags.includes('supplier_merge'),
  iatas: (state) => {
    const filtered = state.company._embedded.iata_plans.filter((ip) => ip.is_active === true);
    return filtered.map((ip) => ip.iata);
  },
  iatasPayable: ({ company }) => {
    const filtered = company._embedded.iata_plans.filter(
      (iataPlan) => iataPlan.is_payable === true
    );
    return filtered.map((ip) => ip.iata);
  },
  iatasShareable: ({ company }) =>
    company._embedded?.iatas?.filter(({ is_managed }) => !is_managed) || [],
  iatasUnfiltered: ({ company }) => company._embedded.iata_plans.map((ip) => ip.iata),
  companyModalTrigger: (state) => state.trigger,
  companyRefresh: (state) => state.refresh,
  companies: (state) => state.companies,
};

const actions = {
  async doCreateCompanyEditing({ commit }, payload) {
    const { data } = await this._vm.$api.companies.create(payload);
    commit('SET_COMPANY_EDITING', data.companies[0]);
  },
  async doCreateCompanyContractorConfig({ commit }, payload) {
    const { data } = await this._vm.$api.companies.createContractorConfig(payload);
    commit('SET_COMPANIES_ATOMIC', { key: 'company._embedded.contractor_configuration', val: data.contractor_configurations[0] });
  },
  async doUpdateCompanyContractorConfig({ commit }, payload) {
    const { data } = await this._vm.$api.companies.updateContractorConfig(payload);
    commit('SET_COMPANIES_ATOMIC', { key: 'company._embedded.contractor_configuration', val: data.contractor_configurations[0] });
  },
  async doUpdateCompanyEditing({ commit }, payload) {
    const { data } = await this._vm.$api.companies.update(payload);
    commit('SET_COMPANY_EDITING', data.companies[0]);
  },
  async doGetCompanyEditing({ commit }, payload) {
    const { data } = await this._vm.$api.companies.get(payload);
    commit('SET_COMPANY_EDITING', data.companies[0]);
  },
  async doGetCompany({ commit }, payload) {
    const { data } = await this._vm.$api.companies.get(payload);
    commit('SET_COMPANY', data.companies[0]);
  },
  async doUpdateCompany({ commit }, payload) {
    const { data } = await this._vm.$api.companies.update(payload);
    commit('SET_COMPANY', data.companies[0]);
  },
  async doGetCompanyContractors({ commit }, companyId) {
    commit('SET_COMPANIES_ATOMIC', { key: 'settings.contractors.loading', val: true });
    try {
      const payload = { id: companyId, zoom: 'employees,invite' };
      const { data } = await this._vm.$api.companies.getContractors(payload);
      // Contractors without an invite should never be rendered, they occur occasionally but aren't intended to exist.
      const contractors = data.contractors.filter(contractor => !!contractor._embedded.invite);
      commit('SET_COMPANIES_ATOMIC', { key: 'contractors', val: contractors });
    } catch (error) {
      reportError(error);
    } finally {
     commit('SET_COMPANIES_ATOMIC', { key: 'settings.contractors.loading', val: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
