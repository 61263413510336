import Vue from 'vue';
import { cloneDeep } from '@/utils/helpers/methods';
import { setAtomicProperty } from '@/utils/store';
import { HTTP } from '@/utils/http';

const defaultInvite = {
  body: 'You\'re invited to join {{ company_name }} on SION to track and manage your travel commissions. To accept this invitation, simply click the link below.',
  commission_split: 20,
  ha: {
    edit_clients: false,
    view_agents: false,
    write_agents: false,
    view_booking_notes: false,
    view_client_info: false,
    view_client_notes: false,
    write_bookings: false,
  },
  ic: {
    delete_bookings: true,
    make_payments: false,
  },
  plan_id: null,
  to: {},
  _embedded: {
    host_agency: {},
    iata_plans: [],
    integration_identities: [],
    invoice_schedule: {
      anchor: null,
      dates: [1, 15],
      rule: 'monthly',
      interval: ['monthly'],
    },
    plan: {},
  },
};

const defaultSettings = {
  resend: false,
};

const state = {
  invite: cloneDeep(defaultInvite),
  inviteSettings: cloneDeep(defaultSettings),
  invites: {
    contractors: [],
    hostAgencies: [],
  },
  settings: {
    contractors: {
      loading: false,
      checked: false,
    }
  }
};

const mutations = {
  ADD_IATA_PLAN_TO_INVITE(state, payload) {
    state.invite._embedded.iata_plans.splice(payload.index, 0, payload.val);
  },
  SET_CONTRACTOR_INVITES(state, payload) {
    state.invites.contractors = payload;
  },
  SET_HOST_AGENCY_INVITES(state, payload) {
    state.invites.hostAgencies = payload;
  },
  SET_IATA_PLAN_FIELD_FOR_INVITE(state, payload) {
    let obj = state.invite._embedded.iata_plans[payload.index];
    const nodes = payload.key.split('.');
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_INVITE(state, payload) {
    state.invite = payload;
  },
  SET_INVITES(state, payload) {
    // used ?
    state.invites = payload;
  },
  SET_INVITE_DEFAULT(state) {
    state.invite = cloneDeep(defaultInvite);
  },
  SET_INVITES_ATOMIC(state, payload) {
    setAtomicProperty(state, payload.key, payload.val);
  },
  SET_INVITE_FIELD(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.invite;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  TOGGLE_INVITE_FIELD(state, field) {
    const nodes = field.key.split('.');
    let obj = state.invite;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, !obj[node]);
      } else {
        obj = obj[node];
      }
    });
  },
  ADD_INTEGRATION_IDENTITY_TO_INVITE(state, payload) {
    state.invite._embedded.integration_identities.splice(payload.index, 0, payload.val);
  },
  SET_INTEGRATION_IDENTITY_FIELD_FOR_INVITE(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.invite._embedded.integration_identities[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
};

const getters = {
  contractorInvites: (state) => state.invites.contractors,
  hostAgencyInvites: (state) => state.invites.hostAgencies,
  invite: (state) => state.invite,
  inviteSettings: (state) => state.settings, // used ?
  contractorInvitesChecked: (state) => state.settings.contractors.checked,
  contractorInvitesLoading: (state) => state.settings.contractors.loading,
};

const actions = {
  async doCreateInvite({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.post('/invites', params);
    if (!ignore) commit('SET_INVITE', data.data.invites[0]);
    return data;
  },
  async doGetContractorInvites({ commit }, payload) {
    commit('SET_INVITES_ATOMIC', { key: 'settings.contractors.loading', val: true });
    const { data } = await this._vm.$api.invites.getContractors(payload);
    commit('SET_CONTRACTOR_INVITES', data.invites);
    commit('SET_INVITES_ATOMIC', { key: 'settings.contractors.loading', val: false });
    return data;
  },
  async doGetHostAgencyInvites({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/companies/${id}/host_agency_invites`, { params });
    commit('SET_HOST_AGENCY_INVITES', data.data.invites);
    return data;
  },
  async doGetInvite({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/invites/${id}`, { params });
    commit('SET_INVITE', data.data.invites[0]);
    return data;
  },
  async doGetPublicInvite({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/invites_public/${id}`, { params });
    commit('SET_INVITE', data.data.invites[0]);
    return data;
  },
  async doUpdateInvite({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/invites/${id}`, params);
    if (!ignore) {
      commit('SET_INVITE', data.data.invites[0]);
    }
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
