import axios from 'axios';

const apiClient = axios.create({
  baseURL:  process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Application: process.env.VUE_APP_TOKEN,
  },
});

// extract nested data from response
apiClient.interceptors.response.use(
  (response) => {
    // or because search endpoints are not as nested
    const data = response.data.data || response.data ;
    return { ...response.data, data };
  },
  (error) => {
    return Promise.reject(error);
  }
);

const setAuthToken = (token) => {
  apiClient.defaults.headers.common['Authorization'] = token;
};

const clearAuthToken = () => {
  delete apiClient.defaults.headers.common['Authorization'];
};

export {
  apiClient,
  setAuthToken,
  clearAuthToken
};
